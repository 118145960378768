import { useFormik } from "formik";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import {
  createTemplate,
  updateTemplate,
} from "../../store/slices/templates/createTemplate";

const NewTemplateFormSms = (props) => {
  const { template, templatePlaceholder, audience, dataSource } = props;
  const dispatch = useDispatch();

  const templateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required field"),
    content: Yup.string().required("Sms template is required field"),
    smsTemplateId: Yup.string().required("smsTemplateId is required field"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: template?.name ? template?.name : "",
      content: template?.content ? template?.content : "",
      smsTemplateId: template?.smsTemplateId ? template?.smsTemplateId : "",
      templateFor: "sms",
      channel: 2,
      status: template?.status ? template?.status : "published",
    },
    validationSchema: templateSchema,

    onSubmit: (values, { resetForm }) => {
      console.log(values);
      console.log("New template form@@@@@audience", audience);

      if (dataSource?.value === "FUNCTION") {
        if (audience?.value) {
          values.campaignFunction = audience.value;
          values.audience = null;
        }
      } else {
        if (audience?.value) {
          values.audience = audience?.value;
          values.campaignFunction = null;
        }
      }

      if (template?.id) {
        values.id = template.id;
        console.log("template update@@@@payload", values);
        dispatch(updateTemplate(values));
      } else {
        dispatch(createTemplate(values));
      }

      //props.onNewTemplateSubmit();
      //resetForm({ values: "" });
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row mb-3">
        <label htmlFor="name" className="col-md-3 col-form-label">
          Name
        </label>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <span className="error text-danger">{formik.errors.name}</span>
          ) : null}
        </div>
      </div>

      <div className="form-group row mb-3">
        <label htmlFor="smsTemplateId" className="col-md-3 col-form-label">
          Sms template Id
        </label>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="smsTemplateId"
            name="smsTemplateId"
            onChange={formik.handleChange}
            value={formik.values.smsTemplateId}
          />
          {formik.touched.smsTemplateId && formik.errors.smsTemplateId ? (
            <span className="error text-danger">
              {formik.errors.smsTemplateId}
            </span>
          ) : null}
        </div>
      </div>
      <div className="form-group row mb-3">
        <label htmlFor="email" className="col-sm-3 col-form-label">
          Status
        </label>
        <div className="col-sm-9">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="statusPublished"
              onChange={formik.handleChange}
              value="published"
              checked={formik.values.status === "published" ? true : false}
            />
            <label className="form-check-label" htmlFor="statusPublished">
              Published
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="statusDraft"
              checked={formik.values.status === "draft" ? true : false}
              value="draft"
              onChange={formik.handleChange}
            />
            <label className="form-check-label" htmlFor="statusDraft">
              Draft
            </label>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <label htmlFor="content" className="form-label">
          Sms Template
        </label>
        <div className="">
          <textarea
            className="form-control"
            rows="7"
            name="content"
            id="content"
            onChange={formik.handleChange}
            value={formik.values.content}
          ></textarea>

          {formik.touched.content && formik.errors.content ? (
            <span className="error text-danger">{formik.errors.content}</span>
          ) : null}
        </div>
      </div>
      <div className="py-3">{templatePlaceholder}</div>

      <div id="btnWrapper" className="text-center mt-4">
        <Button variant="primary" type="submit">
          Submit
        </Button>
      </div>
    </form>
  );
};

export default NewTemplateFormSms;
