import { useFormik } from "formik";
//import { Editor } from "@tinymce/tinymce-react";
import * as Yup from "yup";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  createTemplate,
  updateTemplate,
} from "../../store/slices/templates/createTemplate";
//import { editorConfig } from "../../constants/config";
import { useState, useRef, useEffect } from "react";
import ModalBoxForNewTemplate from "./ModalBoxForNewTemplate";

const NewTemplateForm = (props) => {
  const {
    templatePlaceholder,
    template,
    onNewTemplateSubmit,
    isEdit,
    audience,
    dataSource,
  } = props;
  const [emailDesign, setEmailDesign] = useState(
    template?.emailTemplate?.emailDesign
      ? template?.emailTemplate?.emailDesign
      : ""
  );
  const [emailHtml, setEmailHtml] = useState(
    template?.emailTemplate?.emailHtml ? template?.emailTemplate?.emailHtml : ""
  );
  const [isTemplateValid, setIsTemplateValid] = useState(true);
  const { templateCreated } = useSelector((state) => state.createTemplateSlice);
  const emailEditorContentRef = useRef(null);
  const [isSubmited, setIsSubmited] = useState(false);
  //const [content, setContent] = useState("");

  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  //console.log('loading template........editmode',template)
  const templateSchema = Yup.object().shape({
    name: Yup.string().required("Name is required field"),
    emailSubject: Yup.string().required("Name is required field"),
  });

  useEffect(() => {
    if (templateCreated && isSubmited) {
      onNewTemplateSubmit();
    }
  }, [templateCreated, isSubmited, onNewTemplateSubmit]);

  useEffect(() => {
    console.log("current campaign audience....", audience);
  }, [audience]);

  // useEffect(()=>{
  //   if(!status){

  //       setStatus(user?.status===0 || user?.status===1?user.status:1)
  //   }
  // },[])

  const formik = useFormik({
    enableReinitialize: isEdit ? true : false,
    initialValues: {
      name: template?.name ? template?.name : "",
      content: emailHtml ? emailHtml : "",
      emailSubject: template?.emailSubject ? template?.emailSubject : "",
      emailDesign: template?.emailTemplate?.emailDesign
        ? template?.emailTemplate?.emailDesign
        : emailDesign
        ? emailDesign
        : "",
      emailHtml: template?.emailTemplate?.emailHtml
        ? template?.emailTemplate?.emailHtml
        : emailHtml
        ? emailHtml
        : "",
      status: template?.status ? template?.status : "published",
      channel: 1,
    },
    validationSchema: templateSchema,

    onSubmit: (values, { resetForm }) => {
      //console.log("Template details - ", values);
      setIsTemplateValid(true);
      console.log("submit to create template...");
      if (!emailHtml) {
        console.log("emailData not exist...");
        setIsTemplateValid(false);
        return;
      }

      values.content = "";
      values.emailDesign = emailDesign ? emailDesign : "";
      values.emailHtml = emailHtml;
      if (dataSource?.value === "FUNCTION") {
        if (audience?.value) {
          values.campaignFunction = audience.value;
          values.audience = null;
        }
      } else {
        if (audience?.value) {
          values.audience = audience?.value;
          values.campaignFunction = null;
        }
      }

      if (template?.id) {
        values.id = template.id;
        values.emailTemplateId = template?.emailTemplate?.id
          ? template?.emailTemplate?.id
          : "";
        dispatch(updateTemplate(values));
      } else {
        dispatch(createTemplate(values));
      }

      //console.log("Template details - 2 okkkkkkkkkkkkkkk");
      setIsSubmited(true);
      //props.onNewTemplateSubmit();
      //resetForm({ values: "" });
    },
  });

  const _popUpHandler = (isShow) => {
    setShow(isShow);
  };

  const emailTemplateSaveHandler = (designDetails, htmlDetails) => {
    try {
      setEmailDesign(designDetails);
      setEmailHtml(htmlDetails);
      console.log("Email editor content received....");
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="form-group row mb-3">
        <label htmlFor="name" className="col-md-3 col-form-label">
          Name
        </label>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="name"
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
          {formik.touched.name && formik.errors.name ? (
            <span className="error text-danger">{formik.errors.name}</span>
          ) : null}
        </div>
      </div>
      <div className="form-group row mb-3">
        <label htmlFor="emailSubject" className="col-md-3 col-form-label">
          Email Subject
        </label>
        <div className="col-md-9">
          <input
            type="text"
            className="form-control"
            id="emailSubject"
            name="emailSubject"
            onChange={formik.handleChange}
            value={formik.values.emailSubject}
          />
          {formik.touched.emailSubject && formik.errors.emailSubject ? (
            <span className="error text-danger">
              {formik.errors.emailSubject}
            </span>
          ) : null}
        </div>
      </div>
      <div className="form-group row mb-3">
        <label htmlFor="email" className="col-sm-3 col-form-label">
          Status
        </label>
        <div className="col-sm-9">
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="statusPublished"
              onChange={formik.handleChange}
              value="published"
              checked={formik.values.status === "published" ? true : false}
            />
            <label className="form-check-label" htmlFor="statusPublished">
              Published
            </label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input"
              type="radio"
              name="status"
              id="statusDraft"
              checked={formik.values.status === "draft" ? true : false}
              value="draft"
              onChange={formik.handleChange}
            />
            <label className="form-check-label" htmlFor="statusDraft">
              Draft
            </label>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        <ModalBoxForNewTemplate
          popupHandler={_popUpHandler}
          show={show}
          emailEditorContentRef={emailEditorContentRef}
          templatePlaceholder={templatePlaceholder}
          emailTemplateSaveHandler={emailTemplateSaveHandler}
          emailHtmlContent={emailHtml}
          emailDesignContent={emailDesign}
        />
        <div id="btnWrapper" className="text-center mt-4">
          <Button
            className="btn-lg"
            variant="secondary"
            onClick={() => _popUpHandler(true)}
          >
            Open email template editor
          </Button>
        </div>
        <div>
          {!emailHtml && !isTemplateValid ? (
            <p className="error text-danger mt-3">
              Please design email template using Editor
            </p>
          ) : null}
        </div>
      </div>
      <div className="emailBody" ref={emailEditorContentRef}></div>
      <div className="row mt-4">
        <div className="body">
          {emailHtml && <div dangerouslySetInnerHTML={{ __html: emailHtml }} />}
          {/* <Editor
            apiKey={editorConfig.tinyMceApiKey}
            init={editorConfig.tinyMceEditorOptions}
            value={content}
            onEditorChange={(content) => {
              setContent(content);
              formik.values.content = content;
            }}
          />
          {formik.touched.content && formik.errors.content ? (
            <span className="error text-danger">{formik.errors.content}</span>
          ) : null} */}
        </div>
      </div>

      <div id="btnWrapper" className="text-center mt-4">
        <Button variant="primary" className="btn-lg" type="submit">
          Submit & Continue
        </Button>
      </div>
    </form>
  );
};

export default NewTemplateForm;
