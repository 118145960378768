import { useEffect, useState } from "react";
import Layout from "../../layouts/default";
import { useDispatch, useSelector } from "react-redux";
import { getCampaigns } from "../../store/slices/campaigns/campaigns";
import { createTemplateActions } from "../../store/slices/templates/createTemplate";
import { format } from "date-fns";
import { Button, Table } from "react-bootstrap";
import PaginationList from "../Pagination";
import ReportFilterForm from "./ReportFilterForm";
import { saveAs } from "file-saver";
import { useNavigate } from "react-router-dom";

const Report = () => {
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [filterData, setFilterData] = useState('');
  const [filteredData, setFilteredData] = useState([]); // New state for filtered data
  const { campaigns } = useSelector((state) => state.campaignsSlice);
  const { users } = useSelector((state) => state.usersSlice);
    console.log("MYCAMPPPPP",campaigns,users)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [selectedCampaign, setSelectedCampaign] = useState(null);

  useEffect(() => {
    dispatch(getCampaigns({ page, filterData }));
  }, [dispatch, loading, page, filterData]);

  useEffect(() => {
    dispatch(createTemplateActions.reSetTemplate());
  }, [dispatch]);

  let paginationData = {};
  if (campaigns) {
    paginationData = {
      total: campaigns.total,
      recordsPerPage: campaigns.recordsPerPage,
      page: page, // Correctly assign the page number
      template: 'campaign/report'
    };
  }

  const handleShow = (campaignData) => {
    // Navigate to the campaign report page and pass campaign name and type
    navigate(`/campaign/report/${campaignData.id}`, {
      state: {
        campaignName: campaignData.name,
        campaignType: campaignData?.channel?.name || "Not Available"
      }
    });
  };

  const _tableHeader = () => {
    return (
      <thead>
        <tr>
          <th>S.N</th>
          <th>Name</th>
          <th>Start Date</th>
          <th>Channel</th>
          <th>Status</th>
          <th>Created On</th>
          <th>Modified By</th>
          <th>Modified On</th>
          <th style={{textAlign:"center"}}>Actions</th>
        </tr>
      </thead>
    );
  };

  const _itemsList = () => {
    let pageOffset = +campaigns?.recordsPerPage * (page - 1);
    const dataToDisplay = filteredData.length > 0 ? filteredData : campaigns.data;
    
    if (!dataToDisplay?.length) {
      return <tr><td colSpan="8">No Campaign found!!</td></tr>;
    }

    return dataToDisplay.map((item, index) => {
      return (
        <tr key={item.id}>
          <td>{pageOffset + (index + 1)}</td>
          <td>{item.name}</td>
          <td>{format(new Date(item.scheduledDateTime), 'yyyy-MM-dd HH:mm')}</td>
          <td>{item?.channel?.name ? item.channel.name : ''}</td>
          <td>{item.status}</td>
          <td>{format(new Date(item.createdAt), 'yyyy-MM-dd')}</td>
          <td>{item.updatedByUser?.name||"Unknown User"}</td>
          <td>{format(new Date(item.updatedAt), 'yyyy-MM-dd')}</td>
          <td className="d-flex justify-content-around">
            <Button className="btn btn-btn-primary" onClick={() => handleShow(item)}>
              View Campaign
            </Button>
          </td>
        </tr>
      );
    });
  };

  return (
    <Layout>
      <ReportFilterForm
        setLoading={setLoading}
        setFilterData={setFilterData}
        page={page}
        setPage={setPage}
        template={paginationData.template}
      />

      <Table className="striped bordered hover" id="exTable">
        {_tableHeader()}
        {campaigns && <tbody>{_itemsList()}</tbody>}
      </Table>

      {paginationData && (
        <PaginationList
          paginationData={paginationData}
          setPage={setPage}
          setLoading={setLoading}
        />
      )}
    </Layout>
  );
};

export default Report;
