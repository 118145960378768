import React, { useEffect, useState } from "react";
import { useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button } from "react-bootstrap";
import { saveAs } from "file-saver";
import {
  getCampEmailResponseByID,
  getCampEmailResponseByFilter,
  getCampSMSResponseByID,
  getCampSMSResponseByFilter,
} from "../../store/slices/reportCamp/reportCamp";
import Layout from "../../layouts/default";
import DateFilter from "./DateFilter";

const CampaignReportPage = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const dataPerPage = 10;

  // Get email response data from Redux store
  const emailResponseByID =
    useSelector((state) => state.emailResponseSlice.emailResponseByID) || [];
  // Get sms response data from Redux store
  const smsResponseByID =
    useSelector((state) => state.emailResponseSlice.smsResponseByID) || [];
  const { campaignName, campaignType } = location.state || {};

  // Fetch the campaign data when the component mounts or campaignId changes
  useEffect(() => {
    const fetchCampaignData = () => {
      setLoading(true);
      dispatch(getCampEmailResponseByID(campaignId));
      setLoading(false);
    };

    const fetchCampaignDataforSMS = () => {
      setLoading(true);
      dispatch(getCampSMSResponseByID(campaignId));
      setLoading(false);
    };
    if (campaignType === "sms") {
      fetchCampaignDataforSMS();
    } else {
      fetchCampaignData();
    }
  }, [dispatch, campaignId]);

  // Function to handle applying the date filter
  const applyFilter = ({ from, to }, page = currentPage) => {
    if (campaignType === "sms") {
      setLoading(true);
      dispatch(
        getCampSMSResponseByFilter({ campaignId, from, to, page })
      ).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      dispatch(
        getCampEmailResponseByFilter({ campaignId, from, to, page })
      ).finally(() => {
        setLoading(false);
      });
    }
  };

  // Function to reset filters and fetch initial data
  const handleReset = () => {
    if (campaignType === "sms") {
      setLoading(true);
      setCurrentPage(1); // Reset to first page
      dispatch(getCampSMSResponseByID(campaignId)).finally(() => {
        setLoading(false);
      });
    } else {
      setLoading(true);
      setCurrentPage(1); // Reset to first page
      dispatch(getCampEmailResponseByID(campaignId)).finally(() => {
        setLoading(false);
      });
    }
  };

  // Handle pagination
  const handleNextPage = () => {
    const data = campaignType === "sms" ? smsResponseByID : emailResponseByID;
    if (currentPage < Math.ceil(data.length / dataPerPage)) {
      setCurrentPage(currentPage + 1);
      applyFilter({ from: "", to: "" }, currentPage + 1); // Apply filter for next page
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      applyFilter({ from: "", to: "" }, currentPage - 1); // Apply filter for previous page
    }
  };

  // Function to handle CSV export
  const exportToCSV = () => {
    const headers = `S.N,Campaign Type,Trigger Date,Sent ${campaignType},Failed ${campaignType},Total ${campaignType} Count\n`;
    const csvData = currentData.map(
      (log, index) =>
        `${index + 1},${log.channel?.name || "Email"},${log._id},${
          log.Success_count || 0
        },${log.Failed_count || 0},${log.total || 0}\n`
    );
    const blob = new Blob([headers + csvData.join("")], {
      type: "text/csv;charset=utf-8",
    });
    saveAs(blob, `campaign_report_${campaignId}.csv`);
  };

  // Pagination logic
  const indexOfLastData = currentPage * dataPerPage;
  const indexOfFirstData = indexOfLastData - dataPerPage;
  // const currentData = emailResponseByID.slice(indexOfFirstData, indexOfLastData);

  let currentData = [];
  if (campaignType === "email") {
    currentData = emailResponseByID.slice(indexOfFirstData, indexOfLastData);
  } else if (campaignType === "sms") {
    console.log("smsResponseByID@@@@test", smsResponseByID);
    currentData = smsResponseByID.slice(indexOfFirstData, indexOfLastData);
  } else {
    currentData = emailResponseByID.slice(indexOfFirstData, indexOfLastData);
  }

  console.log("MY CURRENT DATA", currentData);
  return (
    <Layout>
      <div>
        {/* Date Filter and Reset Button */}
        <div className="d-flex align-items-center justify-content-between pt-2">
          <DateFilter applyFilter={applyFilter} resetFilter={handleReset} />

          <Button
            onClick={exportToCSV}
            disabled={currentData.length == 0}
            className="mb-3 ml-3"
            variant="primary"
          >
            Export
          </Button>
        </div>

        {/* Campaign Report Table */}
        <Table striped bordered hover>
          <thead>
            <tr>
              <th>S.N</th>
              <th>Campaign Type</th>
              <th>Trigger Date(dd-mm-yy)</th>
              <th>Sent {campaignType}</th>
              <th>Failed {campaignType}</th>
              <th>Total {campaignType} Count</th>
            </tr>
          </thead>
          <tbody>
            {currentData.length === 0 ? (
              <tr>
                <td colSpan="6" className="text-center">
                  No data available
                </td>
              </tr>
            ) : (
              currentData.map((log, index) => (
                <tr key={index}>
                  <td>{indexOfFirstData + index + 1}</td>
                  <td>{log.channel?.name || campaignType || "Email"}</td>
                  <td>{log._id}</td>
                  <td>{log.success_count || 0}</td>
                  <td>{log.failed_count || 0}</td>
                  <td>{log.total || 0}</td>
                </tr>
              ))
            )}
          </tbody>
        </Table>

        {/* Pagination Controls */}
        <div className="d-flex justify-content-between">
          <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
            Previous
          </Button>
          <span>
            Page {currentPage} of{" "}
            {Math.ceil(emailResponseByID.length / dataPerPage)}
          </span>
          <Button
            onClick={handleNextPage}
            disabled={
              currentPage === Math.ceil(emailResponseByID.length / dataPerPage)
            }
          >
            Next
          </Button>
        </div>
      </div>
    </Layout>
  );
};

export default CampaignReportPage;
