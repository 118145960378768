import { useEffect, useState} from "react";
import Layout from "../../layouts/default";
import Classes from "./style.module.css"
import {Row, Col, Stack, Button} from "react-bootstrap"
import { useParams, useNavigate } from "react-router-dom";
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter"
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import DatePicker from "react-datepicker"
import { createCampaign, updateCampaign, loadCampaign } from "../../store/slices/campaigns/createCampaign";
import TimePicker from "../../components/Campaigns/TimePicker"
import {format } from "date-fns"
import {isValidEmail} from "../../services/utils"
import { 
    loadCampaignRecurrence 
} from "../../store/slices/campaigns/createCampaignRecurrence";
import { getChannels } from "../../store/slices/channels/channels";
import CampaignTagsSelector from "../../components/CampaignTags/Selector"
import CampaignNewTag from "../../components/CampaignTags/NewTag";
import ModalDialog from "../../components/UI/ModalDialog";


const SetCampaignName = () => {
    const [isNameValid, setIsNameValid] = useState(true)
    const [isValidStartDate, setIsValidStartDate] = useState(true)
    const [isValidStartTime, setIsValidStartTime] = useState(true)
    const [isChannelValid, setIsChannelValid] = useState(true)
    const { campaign, updateCampData } = useSelector((state) => state.createCampaignSlice);
    const { campaignRecurrence } = useSelector((state) => state.createCampaignRecurrenceSlice);
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const [scheduledDateTime, setScheduledDateTime] = useState('')
    const [startTime, setStartTime] = useState('')
    const [name, setName] = useState('');
    const [isRepeat, setIsRepeat] = useState(false);
    const [smsSenderId, setSmsSenderId] = useState('');
    const [emailFrom, setEmailFrom] = useState('');
    const [isEmailFromValid, setIsEmailFromValid] = useState(true);
    const [isSmsSenderIdValid, setIsSmsSenderIdValid] = useState(true);
    const [isRedirect, setIsRedirect] = useState(false);
    const { channels } = useSelector((state) => state.channelsSlice);
    const [openTagForm, setOpenTagForm] = useState(false)
    const [tags, setTags] = useState([])
    

    let campaignSchema = Yup.object().shape({
        name: Yup.string().required('Name is required.'),
        channel: Yup.number().required('Channel is required.'),
        startTime:Yup.string().required("Campaign start time is required"),
        scheduledDateTime:Yup.date().required('Scheduled date time is required'),
      });

      const user = JSON.parse(localStorage.getItem("user"));
  console.log("user Id is", user);
    useEffect(()=>{
        let campaignId = params.id
        if(campaignId){
           dispatch(loadCampaign(campaignId))
        }
    },[params.id, dispatch]) 
      
    useEffect(()=>{
        console.log('campaign data loading....error status', isRedirect)
        if((campaign?.data?.id>0) && isRedirect){
            navigate('/campaigns/edit/audience/'+campaign?.data?.id)
        }

        if(!isRedirect){
            console.log('isRepeat testing.....', campaign)
            if(campaign?.data.scheduledDateTime){
                setScheduledDateTime(new Date(campaign?.data.scheduledDateTime))
            }
    
            if(campaign?.data.name){
                setName(campaign?.data.name)
            }

            if(campaign?.data.isRepeat){
                setIsRepeat(campaign?.data.isRepeat)
                console.log('isRepeat default value on load.... ', campaign?.data.isRepeat)
            }

            if(campaign?.data.smsSenderId){
                setSmsSenderId(campaign?.data.smsSenderId)
            }

            if(campaign?.data.emailFrom){
                setEmailFrom(campaign?.data.emailFrom)
                
            }

            if(campaign?.data?.campaignTags.length>0){
                let optionsList = []
                campaign?.data?.campaignTags.map((tag)=>{
                    optionsList.push({label:tag.name, value:tag.id})
                    return ''
                })
                console.log('saved tags okkkkkkkkkkkkkkkkkk ',optionsList)
                setTags(optionsList)
            }

        }
        
    },[campaign,isRedirect, navigate,dispatch])
    
    const startTimeChangeHanfler = (startTimeVal) =>{
        try{
            if(startTimeVal){
                setIsValidStartTime(true)
            }else{
                setIsValidStartTime(false)
            }
            
        }catch(error){

        }
    }

    useEffect(()=>{
        let campaignId = params.id
        if(campaignId){
            dispatch(loadCampaignRecurrence(campaignId))
        }
        
    },[params.id])

    useEffect(()=>{
        if(!channels?.data.length){
            dispatch(getChannels())
        }
    },[channels, dispatch])

    const __getChannelId = () => {
        let campaignId = params.id
        let channelName = updateCampData?.channel?updateCampData?.channel : ''
        if(campaignId){
            channelName = campaign?.data?.channel?.id?campaign?.data.channel.id : ''
        }
        console.log('selected channel',channelName)
        return channelName
    }
    const submitHandler = async () => {
        try{
            
            let campaignId = params.id
            let scheduleDate = ''
            let fullDateTime = ''
            let channelName = __getChannelId()
            setIsNameValid(true)
            setIsValidStartDate(true)
            setIsValidStartTime(true)
            let scheduledDate = scheduledDateTime?scheduledDateTime:''
            
            if(scheduledDate){
                
                if(startTime && startTime.indexOf(':')>0){
                    
                    scheduleDate = format(new Date(scheduledDate), 'yyyy-MM-dd')
                    fullDateTime = scheduleDate+' '+startTime+':00'
                }else{
                    setIsValidStartTime(false)
                }
                
            }else{
                setIsValidStartDate(false)
            }

            if(!name){
                setIsNameValid(false)
            }

            if(!startTime){
                setIsValidStartTime(false)
            }
            
            if(channelName===1 && !emailFrom ){
                console.log('isEmailFromValid not valid....', isEmailFromValid)
                setIsEmailFromValid(false)
                return 
            }
            if(channelName===2 && !smsSenderId ){
                console.log('setIsSmsSenderIdValid not valid....', isSmsSenderIdValid)
                setIsSmsSenderIdValid(false)
                return 
            }
            
            let optionsIds = []
            if(tags.length>0){
                optionsIds = tags.map((tag)=>tag.value)
            }

            let isRepeatBool = isRepeat?1:0;
            let payload = {
                name:name,
                isRepeat:isRepeatBool, 
                scheduledDateTime:fullDateTime,
                channel:channelName,
                smsSenderId:smsSenderId,
                emailFrom:emailFrom,
                campaignTags:optionsIds,
                updatedBy:user?.data?.id,
                createdBy: user?.data?.id
            }
           let checkPayload = {...payload, startTime,}
           console.log('campaign edit.....payload', checkPayload)
           if(!checkPayload.channel){
            setIsChannelValid(false)
            setTimeout(()=>{
                navigate('/campaigns/new')
            },2000)
           }
            campaignSchema
            .validate(checkPayload).then(()=>{
               
                if(campaignId){
                    payload.id = campaignId
                    dispatch(updateCampaign(payload))
                    setIsRedirect(true)
                }else{
                    dispatch(createCampaign(payload))
                    setIsRedirect(true)
                    console.log('new entry done and redirect set')
                }

            })
            .catch(function (err) {
                console.log('Wrong data for submission....',checkPayload, err)
                return 
            });

            
        }catch(error){
            console.log('Error:', error)
        }
    }

    const __emailFromValidate = (event) => {
        try{
            if(!isValidEmail(event.target.value)){
                setIsEmailFromValid(false)
            }
        }catch(error){
            console.log(error)
        }
    }

    const mondalCloseHandler = (status) =>{
        setOpenTagForm(status)
    }

    const tagChangeHandler = (selectedOption) =>{
        console.log('Selected option tagChangeHandler ', selectedOption)
        setTags(selectedOption)
    }

    return (
        <form>
        <Layout>
            <Row>
                <Col md={8}>
                    <div className="pageHeader mb-6">
                        <h3 className="smallHeading mb-3">Create Campaign</h3>
                        <h1>Name your Campaign</h1>
                        
                    </div>
                    <div className="progressSteps mt-5 mb-5">
                        <input className="form-control" 
                        type="text" 
                        name="name" 
                        onChange={(e) =>{
                                setName(e.currentTarget.value)
                                if(e.currentTarget.value){
                                    setIsNameValid(true)
                                }else{
                                    setIsNameValid(false)
                                }
                            }
                          }
                         
                        value={name} 
                        
                        />
                        {!isNameValid && <p className="text-danger">Please enter Campaign name.</p>}
                    </div>
                    <div className="progressSteps">
                        
                        <Stack direction="horizontal" gap={3}>
                            <div className="" style={{width:"72%"}}><CampaignTagsSelector 
                            onTagChange={tagChangeHandler} 
                            selectedTags={tags} 
                            multiOption={true} 
                            showLabel={true}
                            /></div>
                            <div className="pt-4">Or</div> <Button onClick={()=> setOpenTagForm(!openTagForm)} variant="outline-secondary" className="mt-md-4"> Create new tag</Button>
                        </Stack>
                         
                    </div>
                    <div className="progressSteps mt-5 mb-5">
                        <div className="row">
                            <div className="col">
                            <label>Start date</label>
                                <DatePicker 
                                selected={scheduledDateTime}
                                dateFormat="yyyy-MM-d"
                                className="form-control" 
                                minDate={new Date()}
                                name="startDate"
                                onChange={(date)=>{
                                    console.log('New selected date - ',date)
                                    setScheduledDateTime(date)
                                    if(date){
                                        setIsValidStartDate(true)
                                    }
                                    
                                }}
                                />
                                <div className="py-3">{!isValidStartDate && <p className="text-danger">Please select campaign Schedule date.</p>}</div>
                            </div>
                            <div className="col">
                                <label>Start time</label>
                                <TimePicker 
                                setStartTime={setStartTime} 
                                startTime={startTime} 
                                onStartTimeChange={startTimeChangeHanfler}
                                />
                               <div className="py-3">{!isValidStartTime && <p className="text-danger">Please select campaign Schedule Start time.</p>}</div> 
                            </div>
                        </div>
                        
                    </div>
                    <Row>
                        <Col md={7}>
                            
                           {__getChannelId()===1 &&  <div className="progressSteps mt-1 mb-5">
                                <label>Email From</label>
                                <input className="form-control" 
                                type="email" 
                                name="emailFrom" 
                                onBlur={(event)=>__emailFromValidate(event)}
                                onChange={(e) =>{
                                        setEmailFrom(e.currentTarget.value)
                                        if(e.currentTarget.value.trim()){
                                            setIsEmailFromValid(true)
                                        }else{
                                            setIsEmailFromValid(false)
                                        }
                                    }
                                }
                                
                                value={emailFrom} 
                                
                                />
                                {!isEmailFromValid && <p className="text-danger">Please enter Email From.</p>}
                            </div>}
                            {__getChannelId()===2 &&  <div className="progressSteps mt-1 mb-5">
                                <label>SMS Sender Id</label>
                                <input className="form-control" 
                                type="text" 
                                name="smsSenderId" 
                                onChange={(e) =>{
                                        setSmsSenderId(e.currentTarget.value)
                                        if(e.currentTarget.value.trim()){
                                            setIsSmsSenderIdValid(true)
                                        }else{
                                            setIsSmsSenderIdValid(false)
                                        }
                                    }
                                }
                                
                                value={smsSenderId} 
                                
                                />
                                {!isSmsSenderIdValid && <p className="text-danger">Please enter sms sender Id.</p>}
                            </div>}
                        </Col>
                        <Col md={__getChannelId()===4?12:5}>
                            <div className={__getChannelId()===4?"mb-5":"mt-5 mb-5"}>
                                <input type="checkbox" 
                                name="isRepeat" 
                                id="isRepeat" 
                                onChange={(e)=>{
                                    setIsRepeat(e.currentTarget.checked)
                                    console.log('Repeat changed - ', e.currentTarget.checked)
                                }}  
                                checked={isRepeat}
                                
                                /> 
                                
                                <label htmlFor="isRepeat">Repeat this?</label>
                            </div>      
                        </Col>
                    </Row>
                    
                    {!isChannelValid && <div className="text-danger pb-3">Channel is missing. You are redirection to Channel...</div>}
                    <CampaignFooter 
                        nextStep="Select audience" 
                        buttonTitle="Create Campaign" onNextStepHandler={submitHandler}
                     />
                     <ModalDialog 
                     isShow={openTagForm} 
                     setIsShow={openTagForm} 
                     onClose={mondalCloseHandler} 
                     title="Add New Tag"
                     >
                        <CampaignNewTag />       
                     </ModalDialog>
                     
                </Col> 
                <Col md={4} className={Classes.sidebarBackground}>
                    <div className={Classes.sidebarBackground} style={{height:'100VH'}}>
                        <ProgressBar 
                        campaign={campaign} 
                        updateCampData={updateCampData} 
                        campaignRecurrence={campaignRecurrence} 
                        allchannels={channels} />
                    </div>
                    
                </Col>
            </Row>
            
        </Layout>
        </form>
    )
}

export default SetCampaignName;