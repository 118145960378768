import { useEffect, useState, useMemo } from "react";
import Form from "react-bootstrap/Form";
import { useParams, useNavigate } from "react-router-dom";
import Select from "react-select";
import Layout from "../../layouts/default";
import Classes from "./style.module.css";
import { Row, Col } from "react-bootstrap";
import ProgressBar from "../../components/Campaigns/ProgressBar";
import CampaignFooter from "../../components/Campaigns/CampaignFooter";
import { useDispatch, useSelector } from "react-redux";
import AsyncSelect from "react-select/async";

import * as Yup from "yup";

import {
  updateCampaign,
  loadCampaign,
} from "../../store/slices/campaigns/createCampaign";
import { getAudiences } from "../../store/slices/audiences/audiences";
import AudienceSelector from "../../components/Audience/AudienceSelector";
import { loadCampaignRecurrence } from "../../store/slices/campaigns/createCampaignRecurrence";
import { audienceActions } from "../../store/slices/audiences/audience";
import { getCampaignErrorCount } from "../../store/slices/campaigns/campaignErrors";

const SetCampaignAudience = () => {
  const [isAudienceValid, setIsNameValid] = useState(true);
  const [dataSourceType, setDataSourceType] = useState({
    label: "Audience search by",
    value: "",
  });
  const [dataSourceTypeError, setDataSourceTypeError] = useState(null);
  const { campaign, updateCampData } = useSelector(
    (state) => state.createCampaignSlice
  );
  const { audiences } = useSelector((state) => state.audiencesSlice);
  const [selectedAudience, setSelectedAudience] = useState({
    label: "Select Audience",
    value: "",
  });
  const { campaignRecurrence } = useSelector(
    (state) => state.createCampaignRecurrenceSlice
  );
  const [checkAudience, setCheckAudience] = useState(0);
  const [selected, setSelected] = useState("yes");

  const dispatch = useDispatch();
  const params = useParams();
  const navigate = useNavigate();

  let campaignSchema = Yup.object().shape({
    id: Yup.number().required("Name is required."),
    audience: Yup.number().required("Audience is required."),
    // dataSourceType: Yup.string().required("Audience search by is required."),
  });

  useEffect(() => {
    setSelectedAudience({
      label: "Select Audience",
      value: "",
    });
    if (checkAudience === 1) {
      setSelected("no");
    } else {
      setSelected("yes");
    }

    let payload = {
      page: 1,
      isAll: "yes",
    };
    if (checkAudience === 0) {
      payload.filterData = {
        hasGuestAudience: "creditq",
        dataSource: dataSourceType.value,
      };
      if (dataSourceType?.value) {
        dispatch(getAudiences(payload));
      }
      console.log("Call audience@@@@creditq", payload);
    } else {
      payload.filterData = {
        hasGuestAudience: "gust",
      };
      dispatch(getAudiences(payload));
      console.log("Call audience@@@@guest", payload);
    }
  }, [dispatch, checkAudience, dataSourceType]);

  useEffect(() => {
    let campaignId = params.id;
    if (campaignId) {
      dispatch(loadCampaign(campaignId));
      dispatch(loadCampaignRecurrence(campaignId));
    }
  }, [params.id, dispatch]);

  useEffect(() => {
    let campaignId = params.id;
    if (campaignId) {
      dispatch(loadCampaignRecurrence(campaignId));
    }
  }, [params.id]);

  const dataSourceOptions = useMemo(() => {
    return [
      { value: "QUERY", label: "Query" },
      { value: "FUNCTION", label: "Function" },
    ];
  }, []);

  useEffect(() => {
    if (campaign && campaign.data?.dataSource) {
      const selectedDataSource = dataSourceOptions.find(
        (item) => item.value === campaign.data?.dataSource
      );
      setDataSourceType({
        label: selectedDataSource.label,
        value: campaign.data?.dataSource,
      });
    }
  }, [campaign, dataSourceOptions]);

  useEffect(() => {
    console.log("Audience reset");
    dispatch(audienceActions.resetAudience());
  }, [selectedAudience, dispatch]);

  useEffect(() => {
    if (campaign) {
      if (campaign.data.audience) {
        if (campaign.data.audience.hasGuestAudience === "creditq") {
          setCheckAudience(0);
        } else {
          setCheckAudience(1);
        }
      }
      if (campaign?.data?.campaignFunction) {
        if (campaign?.data?.campaignFunction) {
          setCheckAudience(0);
        } else {
          setCheckAudience(1);
        }
      }
    }
    console.log("campaignData@@@@@result", campaign);
  }, [campaign]);

  const dataSourceChangeHandler = (event) => {
    console.log("dataSourceChangeHandler", event);
    if (event?.value) {
      setDataSourceTypeError(null);
    }

    setDataSourceType(event);
  };

  const submitHandler = async () => {
    try {
      if (!dataSourceType?.value && !checkAudience) {
        console.log("Data source is missing....");
        setDataSourceTypeError("Please select Audience search by");
        return;
      }
      let payload = {
        audience: selectedAudience.value,
        id: campaign?.data.id,
        isRepeat: campaign?.data.isRepeat,
        dataSource: dataSourceType?.value ? dataSourceType?.value : null,
      };
      console.log("setAudience@@@@payload", payload);
      await campaignSchema
        .isValid(payload)
        .then(function (valid) {
          setIsNameValid(valid);
          if (valid) {
            dispatch(updateCampaign(payload));
            //refresh error notification
            setTimeout(() => {
              dispatch(getCampaignErrorCount());
            }, 500);
            navigate("/campaigns/edit/others/" + campaign?.data?.id);
          }
        })
        .catch((error) => {
          console.log("Eroorrrrrrrrrr - ", error);
        });
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const clickEvent = (event) => {
    if (event === "gustAudience") {
      setCheckAudience(1);
    } else {
      setCheckAudience(0);
    }
  };

  console.log("audiences@result", audiences);

  return (
    <Layout>
      <Row>
        <Col md={8}>
          <div className="pageHeader mb-6">
            <h3 className="smallHeading mb-3">Create Campaign</h3>
            <h1>Select Audience</h1>
          </div>
          <div>
            <Form>
              {["radio"].map((type) => (
                <div key={`inline-${type}`} className="mb-3">
                  <Form.Check
                    inline
                    label="Creditq Audience"
                    name="selectAudience"
                    type={type}
                    id="creditqAudience"
                    value="yes"
                    checked={selected === "yes"}
                    onClick={(e) => {
                      clickEvent(e.target.id);
                    }}
                    // checkAudience == defaultChecked ? :""
                  />
                  <Form.Check
                    inline
                    label="Gust Audience"
                    name="selectAudience"
                    type={type}
                    id="gustAudience"
                    value="no"
                    checked={selected === "no"}
                    onClick={(e) => {
                      clickEvent(e.target.id);
                    }}
                  />
                </div>
              ))}
            </Form>
          </div>
          {!checkAudience && (
            <div>
              <Form>
                <Select
                  options={dataSourceOptions}
                  value={dataSourceType}
                  onChange={dataSourceChangeHandler}
                  placeholder="Audience search by"
                  name="dataSourceType"
                />
              </Form>
              {dataSourceTypeError && (
                <div className="errorText">{dataSourceTypeError}</div>
              )}
            </div>
          )}

          <div className="progressSteps mt-5 mb-5">
            {audiences && (
              <AudienceSelector
                audiences={audiences}
                selectedAudience={selectedAudience}
                setSelectedAudience={setSelectedAudience}
                campaign={campaign}
                checkAudience={checkAudience}
                dataSourceType={dataSourceType}
              />
            )}
            {!isAudienceValid && (
              <p className="text-danger pt-3 text-center">
                Please select or create Audience.
              </p>
            )}
          </div>
          <CampaignFooter
            nextStep="Select Others"
            buttonTitle="Set Audience"
            onNextStepHandler={submitHandler}
          />
        </Col>
        <Col md={4} className={Classes.sidebarBackground}>
          <div
            className={Classes.sidebarBackground}
            style={{ height: "100VH" }}
          >
            <ProgressBar
              campaign={campaign}
              updateCampData={updateCampData}
              campaignRecurrence={campaignRecurrence}
            />
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default SetCampaignAudience;
