import { Fragment, useEffect, useState } from "react";
import { Col, Button, Row } from "react-bootstrap";
import Select from "react-select";
import NewAudience from "../Campaigns/NewAudience";
import NewGustAudience from "../Campaigns/NewGustAudience";
import AudienceTestResult from "./AudiencePreview";
import { getAudienceByQuery } from "../../store/slices/campaigns/createCampaign";
import { useSelector, useDispatch } from "react-redux";

const AudienceSelector = (props) => {
  const {
    audiences,
    selectedAudience,
    setSelectedAudience,
    campaign,
    checkAudience,
    dataSourceType,
  } = props;
  console.log("AudienceSelector####", campaign);
  const [showTestPop, setShowTestPop] = useState(false);
  const [show, setShow] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [gustShow, setGustShow] = useState(false);
  const [gustIsEdit, setGustIsEdit] = useState(false);
  const [isValid, setIsValid] = useState(true);
  const [chhanelName, setChhanelName] = useState(false);
  const [options, setOptions] = useState([
    { label: "Select Audience", value: "" },
  ]);
  const dispatch = useDispatch();
  const previewAudiences = useSelector(
    (state) => state.audienceResultsSlice.audiences
  );
  const newCreatedAUdience = useSelector(
    (state) => state.createAudiencesSlice.audience
  );

  const _audienceChangeHandle = (event) => {
    console.log("Audicen - #######", event);
    setSelectedAudience(event);
  };

  const getAudienceDropDownOptions = (options) => {
    let optionsList = [];

    for (let i = 0; i < options.length; i++) {
      optionsList.push({
        ...options[i],
        label: options[i].name,
        value: options[i].id,
      });
    }
    return optionsList;
  };
  useEffect(() => {
    if (campaign) {
      setChhanelName(campaign.data.channel.name);
    }
  }, [campaign]);
  useEffect(() => {
    if (newCreatedAUdience && newCreatedAUdience?.data?.id > 0) {
      let selectedOption = {
        label: newCreatedAUdience.data.name,
        value: newCreatedAUdience.data.id,
        ...newCreatedAUdience?.data,
      };

      setSelectedAudience(selectedOption);
    }
  }, [newCreatedAUdience]);

  useEffect(() => {
    console.log("audiences#####", audiences);
    if (audiences && audiences.data.length > 0) {
      let optionsList = getAudienceDropDownOptions(audiences.data);
      setOptions((prev) => [...optionsList]);
    }

    if (campaign?.data?.audience) {
      let hasGuestAudience = campaign?.data?.audience.hasGuestAudience;
      if (
        (hasGuestAudience === "creditq" && checkAudience === 0) ||
        (hasGuestAudience === "gust" && checkAudience === 1)
      ) {
        console.log("check hasGuestAudience and checkAudience condition true");
        let selectedOption = {
          label: campaign.data.audience.name,
          value: campaign.data.audience.id,
          ...campaign?.data?.audience,
        };
        setSelectedAudience(selectedOption);
      } else {
        console.log("check hasGuestAudience and checkAudience condition false");
        setSelectedAudience({
          label: "Select Audience",
          value: "",
        });
      }
    }
    if (campaign?.data?.campaignFunction) {
      let selectedOption = {
        label: campaign.data.campaignFunction.name,
        value: campaign.data.campaignFunction.id,
        ...campaign?.data?.campaignFunction,
      };
      setSelectedAudience(selectedOption);
    }
  }, [audiences, checkAudience]);

  const _closePopHandle = () => {
    try {
      setShowTestPop(false);
    } catch (error) {
      console.log(error);
    }
  };

  const _popUpHandler = (isShow, isEdit) => {
    setShow(isShow);
    setIsEdit(isEdit);
  };

  const gustAudiencePopUpHandler = (isShow, isEdit) => {
    setGustShow(isShow);
    setGustIsEdit(isEdit);
  };

  const _previewHandler = () => {
    try {
      if (!selectedAudience.value) {
        setIsValid(false);
        return;
      }
      console.log("selectedAudience#####", selectedAudience);
      let payload = {
        sqlQuery: selectedAudience.sqlQuery
          ? selectedAudience.sqlQuery
          : selectedAudience?.name,
        startLimit: 0,
        endLimit: 5,
        audienceId: selectedAudience.id,
        hasGuestAudience: checkAudience === 0 ? "creditq" : "gust",
        dataSource: dataSourceType?.value ? dataSourceType?.value : null,
      };
      console.log("preview@@@payload", payload);
      dispatch(getAudienceByQuery(payload));
      setShowTestPop(true);
    } catch (error) {
      console.log(error);
    }
  };

  console.log("previewAudiences@@@@test", previewAudiences);

  return (
    <Fragment>
      <Row>
        <Col sm={9}>
          <Select
            value={selectedAudience}
            onChange={_audienceChangeHandle}
            options={options}
          />
          {selectedAudience && previewAudiences && (
            <AudienceTestResult
              audience={selectedAudience}
              previewAudiences={previewAudiences}
              closeTestPop={_closePopHandle}
              showTestPop={showTestPop}
            />
          )}
        </Col>
        <Col sm={3}>
          <Button variant="secondary" onClick={_previewHandler}>
            Test
          </Button>{" "}
          {selectedAudience?.value &&
          checkAudience === 0 &&
          dataSourceType?.value !== "FUNCTION" ? (
            <Button
              variant="secondary"
              className="ml-3"
              onClick={() => _popUpHandler(true, true)}
            >
              Edit
            </Button>
          ) : (
            selectedAudience?.value &&
            dataSourceType?.value !== "FUNCTION" && (
              <Button
                variant="secondary"
                className="ml-3"
                onClick={() => gustAudiencePopUpHandler(true, true)}
              >
                Edit
              </Button>
            )
          )}
        </Col>
      </Row>
      {!selectedAudience?.value && !isValid && (
        <Row>
          <Col className="alert-danger mt-3 p-2">Please select an Audience</Col>
        </Row>
      )}
      {dataSourceType?.value !== "FUNCTION" && (
        <>
          <Row className="my-5">
            <Col>
              <h3 className="text-center">OR</h3>
            </Col>
          </Row>
          <Row>
            {checkAudience === 0 ? (
              <Col className="text-center">
                <NewAudience
                  popupHandler={_popUpHandler}
                  show={show}
                  selectedAudience={selectedAudience}
                  isEdit={isEdit}
                />
                <Button
                  className="btn"
                  variant="primary"
                  onClick={() => _popUpHandler(true, false)}
                >
                  Add New Audience
                </Button>
              </Col>
            ) : (
              <Col className="text-center">
                <NewGustAudience
                  popupHandler={gustAudiencePopUpHandler}
                  show={gustShow}
                  selectedAudience={selectedAudience}
                  isEdit={gustIsEdit}
                  chhanelName={chhanelName}
                />
                <Button
                  className="btn"
                  variant="primary"
                  onClick={() => gustAudiencePopUpHandler(true, false)}
                >
                  Add New Gust Audience
                </Button>
              </Col>
            )}
          </Row>
        </>
      )}
    </Fragment>
  );
};

export default AudienceSelector;
