import { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import Layout from "../../layouts/default";
import Classes from "./style.module.css";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import CreateTemplateForm from "../../components/Template/NewTemplateForm";
import CreateTemplateFormForSms from "../../components/Template/NewTemplateFormSms";
import CreateTemplateFormForWhatsapp from "../../components/Template/NewTemplateFormWhatsapp";
import { getQueryPlaceHolders, copyselection } from "../../services/utils";
import Select from "react-select";
import { getAudiences } from "../../store/slices/audiences/audiences";
import { getAudienceSelectedKeys } from "../../services/audiences/index";

const NewTemplate = () => {
  const [dataSourceType, setDataSourceType] = useState({
    label: "Audience search by",
    value: "",
  });
  const { channels } = useSelector((state) => state.channelsSlice);
  const { audiences } = useSelector((state) => state.audiencesSlice);
  const [channel, setChannel] = useState({
    value: "",
    label: "Select Channel",
  });
  const [audience, setAudience] = useState({
    value: "",
    label: "Select Audience",
    sqlQuery: "",
    hasGuestAudience: "",
  });
  const [channelOptions, setChannelOptions] = useState([]);
  const [audienceOptions, setAudienceOptions] = useState([]);
  const [placeholderArr, setplaceholderArr] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (channels && channels.data.length > 0) {
      let optionsList = getChannelDropDownOptions(channels.data);
      setChannelOptions(optionsList);
    }
  }, [channels]);

  const dataSourceOptions = useMemo(() => {
    return [
      { value: "QUERY", label: "Query" },
      { value: "FUNCTION", label: "Function" },
      { value: "GUEST", label: "Guest" },
    ];
  }, []);

  //load audience
  useEffect(() => {
    let payload = {
      page: 1,
      filterData: {
        hasGuestAudience:
          dataSourceType?.value === "QUERY" ||
          dataSourceType?.value === "FUNCTION"
            ? "creditq"
            : "gust",
        dataSource: dataSourceType.value,
      },
      isAll: "yes",
    };
    dispatch(getAudiences(payload));
  }, [dataSourceType, dispatch]);

  useEffect(() => {
    if (audiences && audiences.data.length > 0) {
      let optionsList = getAudienceDropDownOptions(audiences.data);
      setAudienceOptions(() => optionsList);
    }
  }, [audiences]);

  const getChannelDropDownOptions = (options) => {
    let optionsList = [{ label: "Select Channel", value: "" }];

    for (let i = 0; i < options.length; i++) {
      optionsList.push({
        ...options[i],
        label: options[i].name,
        value: options[i].id,
      });
    }
    return optionsList;
  };

  const getAudienceDropDownOptions = (options) => {
    let optionsList = [{ label: "Select Audience", value: "" }];

    for (let i = 0; i < options.length; i++) {
      optionsList.push({
        label: options[i]?.readableName
          ? options[i]?.readableName
          : options[i]?.name,
        value: options[i].id,
        sqlQuery: options[i].sqlQuery,
        hasGuestAudience: options[i].hasGuestAudience,
        ...options[i],
      });
    }
    return optionsList;
  };

  useEffect(() => {
    console.log("audience######$$$$$", audience);
    if (audience) {
      getPlaceholderData();
    }
  }, [audience]);

  const getPlaceholderData = async () => {
    let placeholder;
    if (audience?.hasGuestAudience === "creditq" || audience?.queryVars?.data) {
      if (!audience?.queryVars?.data) {
        placeholder = getQueryPlaceHolders(audience?.sqlQuery);
      } else {
        placeholder = audience?.queryVars?.data;
      }
    } else {
      let audienceId = audience?.id;

      let audienceData = await getAudienceSelectedKeys(audienceId);

      if (audienceData.audienceQueryVar[0].selectKeys.data.length) {
        let queryVars = audienceData.audienceQueryVar[0].selectKeys.data
          ? audienceData.audienceQueryVar[0].selectKeys.data
          : [];
        console.log("queryVars#####", queryVars);
        placeholder = queryVars;
      }
    }

    console.log("placeholder ##### - ", placeholder);
    setplaceholderArr(placeholder);
  };

  const _getPlaceHolderFromAudienceQuery = () => {
    try {
      if (!placeholderArr.length) return;

      return placeholderArr.map((item) => {
        let asExist = item.indexOf("as");
        let fieldName = "";
        if (asExist > 0) {
          fieldName = item.split("as")[1].trim();
        } else {
          fieldName = item.trim();
        }
        return (
          <span key={fieldName} className="inline-block px-2">
            <span id={fieldName}>{`{{${fieldName}}}`}</span>{" "}
            <svg
              onClick={() => copyselection(fieldName)}
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              className="bi bi-clipboard"
              viewBox="0 0 16 16"
            >
              <path d="M4 1.5H3a2 2 0 0 0-2 2V14a2 2 0 0 0 2 2h10a2 2 0 0 0 2-2V3.5a2 2 0 0 0-2-2h-1v1h1a1 1 0 0 1 1 1V14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V3.5a1 1 0 0 1 1-1h1v-1z" />
              <path d="M9.5 1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-3a.5.5 0 0 1-.5-.5v-1a.5.5 0 0 1 .5-.5h3zm-3-1A1.5 1.5 0 0 0 5 1.5v1A1.5 1.5 0 0 0 6.5 4h3A1.5 1.5 0 0 0 11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3z" />
            </svg>
          </span>
        );
      });
    } catch (error) {}
  };

  const _channelChangeHandle = (event) => {
    console.log("change event ##### channel", event);
    setChannel(event);
  };
  const _audienceChangeHandle = (event) => {
    console.log("change event ##### audience", event);
    setAudience(event);
  };

  const templatSubmitHandler = () => {
    navigate("/templates");
  };

  const dataSourceChangeHandler = (event) => {
    console.log("dataSourceChangeHandler", event);
    setDataSourceType(event);
  };

  return (
    <Layout>
      <Row>
        <Col md={8}>
          <div className="pageHeader mb-6">
            <h3 className="smallHeading mb-3">Create a Template</h3>
          </div>

          <div className="form-group row mb-3">
            <label htmlFor="channels" className="col-md-3 col-form-label">
              Channels
            </label>
            <div className="col-md-9">
              <Select
                className={Classes.selectMaxWidth}
                value={channel}
                onChange={_channelChangeHandle}
                options={channelOptions}
                name="channel"
                id="channels"
              />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label htmlFor="audiences" className="col-md-3 col-form-label">
              Audience search by
            </label>
            <div className="col-md-9">
              <Select
                className={Classes.selectMaxWidth}
                options={dataSourceOptions}
                value={dataSourceType}
                onChange={dataSourceChangeHandler}
                placeholder="Audience search by"
                name="dataSourceType"
              />
            </div>
          </div>
          <div className="form-group row mb-3">
            <label htmlFor="audiences" className="col-md-3 col-form-label">
              Audience
            </label>
            <div className="col-md-9">
              <Select
                className={Classes.selectMaxWidth}
                value={audience}
                onChange={_audienceChangeHandle}
                options={audienceOptions}
                name="audience"
                id="audiences"
              />
            </div>
          </div>
          <div className="progressSteps mt-5 mb-5">
            {audience.value && channel.value === 1 && (
              <CreateTemplateForm
                onNewTemplateSubmit={templatSubmitHandler}
                templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                audience={audience.value}
              />
            )}

            {audience.value && channel.value === 2 && (
              <CreateTemplateFormForSms
                onNewTemplateSubmit={templatSubmitHandler}
                templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                audience={audience.value}
              />
            )}

            {audience.value && channel.value === 4 && (
              <CreateTemplateFormForWhatsapp
                onNewTemplateSubmit={templatSubmitHandler}
                templatePlaceholder={_getPlaceHolderFromAudienceQuery()}
                audience={audience.value}
              />
            )}
          </div>
        </Col>
      </Row>
    </Layout>
  );
};

export default NewTemplate;
