import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { setMessage } from "../message";
import CampaignsService from "../../../services/campagins";
import audiencesService from "../../../services/audiences";
import { toast } from "react-toastify";

export const createCampaign = createAsyncThunk(
  "campagins/createCampaign",
  async (payload, thunkAPI) => {
    try {
      const result = await CampaignsService.getCreateCampaign(payload);
      if (!result?.data?.error) {
        return result;
      } else {
        //thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const updateCampaign = createAsyncThunk(
  "campagins/updateCampaign",
  async (payload, thunkAPI) => {
    try {
      console.log("updating campaign data from thunk function ", payload);
      const result = await CampaignsService.getUpdateCampaign(payload);
      if (!result?.data?.error) {
        thunkAPI.dispatch(setMessage(result.data.message));
        console.log(
          "updating campaign data from thunk function..... ",
          result.data.message
        );
        toast.success(result.data.message);
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        toast.error(result.data.message);
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const loadCampaign = createAsyncThunk(
  "campagins/loadCampaign",
  async (id, thunkAPI) => {
    try {
      const result = await CampaignsService.getCampaign(id);
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

export const getAudienceByQuery = createAsyncThunk(
  "campagins/getAudienceByQuery",
  async (payload, thunkAPI) => {
    try {
      const result = await audiencesService.fetchAudiencesByQuery(payload);
      console.log(
        "loaded audience data okkkkkkkkkkkkkkkkkkk",
        result,
        payload.startLimit,
        payload.endLimit,
        payload.audienceId,
        payload.sqlQuery,
        payload.hasGuestAudience
      );
      if (result) {
        return result;
      } else {
        thunkAPI.dispatch(setMessage(result.data.message));
        return thunkAPI.rejectWithValue();
      }
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      thunkAPI.dispatch(setMessage(message));
      return thunkAPI.rejectWithValue();
    }
  }
);

const initialState = {
  campaign: null,
  updateCampData: null,
  campaignCreateError: null,
};

const createCampaignSlice = createSlice({
  name: "createupdatecampaign",
  initialState,
  reducers: {
    setCampaign(state, action) {
      state.updateCampData = { ...state.updateCampData, ...action.payload };
    },
    reSetCampaign(state, _) {
      state.updateCampData = null;
      state.campaign = null;
    },
  },

  extraReducers: {
    [createCampaign.fulfilled]: (state, action) => {
      console.log("createcampaign.fulfilled", action);
      state.updateCampData = {
        ...state.updateCampData,
        ...action.payload.data,
      };
      state.campaign = action.payload;
      //state.redirectNext = true;
    },
    [createCampaign.rejected]: (state, action) => {
      state.campaign = null;
      //state.redirectNext = null;
    },
    [updateCampaign.fulfilled]: (state, action) => {
      console.log("update.fulfilled okkkkkkkkkk", action);
      state.updateCampData = action.payload.data;
      state.campaign = action.payload;
      //state.redirectNext = true;
    },
    [updateCampaign.rejected]: (state, action) => {
      //state.campaign = null;
      console.log("update.rejected okkkkkkkkkk", action);
      state.campaignCreateError = true;
      //state.redirectNext = null;
    },
    [loadCampaign.fulfilled]: (state, action) => {
      console.log("loadCampaign.fulfilled", action.payload);
      state.campaign = action.payload;
    },
    [loadCampaign.rejected]: (state, action) => {
      state.campaign = null;
    },
  },
});

const sqlQueryResultsSlice = createSlice({
  name: "sqlQueryResultsSlice",
  initialState: {
    audiences: null,
  },
  extraReducers: {
    [getAudienceByQuery.fulfilled]: (state, action) => {
      console.log("getAudienceByQuery.fulfilled", action);
      state.audiences = action.payload;
    },
    [getAudienceByQuery.rejected]: (state, action) => {
      state.audiences = null;
    },
  },
});

export const audienceResultsSlice = sqlQueryResultsSlice.reducer;

export const createCampaignActions = createCampaignSlice.actions;
export default createCampaignSlice.reducer;
