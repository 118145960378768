import React from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // For validation
import { Button, Row, Col } from 'react-bootstrap';

const DateFilter = ({ applyFilter, resetFilter }) => {
  // Validation schema using Yup
  const validationSchema = Yup.object({
    from: Yup.date().required('From date is required').nullable(),
    to: Yup.date()
      .required('To date is required')
      .nullable()
      .min(Yup.ref('from'), 'To date cannot be before from date'),
  });

  return (
    <Formik
      initialValues={{ from: '', to: '' }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        setSubmitting(true);
        applyFilter(values); // Apply filter with selected dates
        setSubmitting(false);
      }}
    >
      {({ isSubmitting, resetForm }) => (
        <Form noValidate>
          <Row className="align-items-center">
            <Col xs={12} md={5} className="mb-3">
              <div className="form-group">
                <label htmlFor="from">From Date:</label>
                <Field
                  name="from"
                  type="date"
                  className="form-control"
                />
                <ErrorMessage
                  name="from"
                  component="div"
                  className="text-danger"
                />
              </div>
            </Col>

            <Col xs={12} md={5} className="mb-3">
              <div className="form-group">
                <label htmlFor="to">To Date:</label>
                <Field
                  name="to"
                  type="date"
                  className="form-control"
                />
                <ErrorMessage
                  name="to"
                  component="div"
                  className="text-danger"
                />
              </div>
            </Col>

            <Col xs={12} md={2} className="mb-3 d-flex justify-content-start align-items-center gap-2 w-100">
              <Button
                type="submit"
                disabled={isSubmitting}
                variant="primary"
                className="mr-2" // Adds margin to the right of the Apply Filter button
              >
                Filter
              </Button>

              <Button
                type="button"
                onClick={() => {
                  resetForm(); // Reset the form
                  resetFilter(); // Reset the data
                }}
                variant="secondary"
              >
                Reset
              </Button>
            </Col>
          </Row>
        </Form>
      )}
    </Formik>
  );
};

export default DateFilter;
